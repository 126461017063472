













import { Component, Prop, Vue } from 'vue-property-decorator'
import {
	getPaymentIntent,
	OnboardingPaymentIntent,
} from '@/clients/cpinblocks'
import { BigNumber } from 'bignumber.js'

// TODO: prevent dark mode => force it to false whatever the value in the store without changing the store value

@Component({
	components: {
	},
})
export default class Checkout extends Vue {
	@Prop() intentId?: string
  @Prop() lang?: string
	@Prop() status!: string
  @Prop({default: 'ico'}) type!: string

	private loading = true
	private paymentIntent: OnboardingPaymentIntent | null = null

	get colorWarning (): string {
		return '#FF9800'
	}

	get currentLang (): string {
    if (this.paymentIntent?.lang) {
      return this.paymentIntent?.lang
    } else if (this.lang) {
      return this.lang
    }
		return 'en'
	}

	textStyle (color: string, size: number): string {
		return `color: ${color}; font-size: ${size}px;`
	}

	private tr (k: string, v?: any): string {
		return this.$t(k, this.currentLang, v) ? this.$t(k, this.currentLang, v).toString() : ''
	}

	private async mounted (): Promise<void> {
		if (this.intentId && this.intentId !== 'direct') {
			this.paymentIntent = await getPaymentIntent(this.$store.state.jwt, this.intentId)
		}
		this.loading = false
	}

}
